import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'

export default function Layout() {
    const { uid } = useParams()
    const [galleryFiles, setGalleryFiles] = useState([])
    useEffect(() => {
        axios.get(`https://api.iphoto.nz/files/${uid}`).then(response => {
            setGalleryFiles(response.data)
        })
    }, [])

    const URLS = galleryFiles.map((gallery) => {
        return (
            gallery.urls?.map((url, i) => {
                if (url.includes('-thumb') && !url.includes('._')) {
                    const fullres = url.replace('-thumb', '')
                    return (

                        // <div key={url} style={{}}>
                        //     <div style={{ width: '30%' }}>
                        //         <a href={`https://api.iphoto.nz/photo?url=${fullres}`} key={url}>
                        //             <img src={`https://api.iphoto.nz/photo?url=${url}`} />
                        //         </a>
                        //     </div>
                        // </div>

                        <div key={url} className="gallery__item">
                        <input type="radio" id={`img-${i}`} checked name="gallery" className="gallery__selector" />
                        <a href={`https://api.iphoto.nz/photo?url=${fullres}`} key={url}>
                            <img className="gallery__img" src={`https://api.iphoto.nz/photo?url=${url}`} alt="" />
                            </a>
                        <label htmlFor={`img-${i}`} className="gallery__thumb"><img src={`https://api.iphoto.nz/photo?url=${url}`} alt="" /></label>
                        </div>

                    )
                }

            })

        )

    }

    )

    return (
        // <div style={{ width: '100vw', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <section class="gallery">

            {URLS}
        </section>
        // </div>
        )
}
